import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

import { Text } from "./language";

export class Loading extends Component {
    render() {
        return (
            <Spinner animation="border" variant="primary">
                <span className="sr-only">
                    <Text tid="Loading" />
                </span>
            </Spinner>
        );
    }
}

export default Loading;
