import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { Text } from "./language";

export class NotFound extends Component {
    render() {
        return (
            <div id="notfound">
                <div className="section-title">
                    <h2>
                        <Text tid="404" />
                    </h2>
                </div>
                <Card>
                    <Card.Body>
                        <Text tid="NotFound" />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default NotFound;
