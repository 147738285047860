import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { LanguageContext } from "./language";
import { StaticContent } from "./staticcontent";

export class Privacy extends Component {
    // Adding language context reference here
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // Initial state
        this.state = { languageSet: false };
    }

    getPrivacyUrl() {
        const languageContext = this.context;
        const language = languageContext.userLanguage;

        switch (language) {
            case "es":
                return "privacy-es.html";

            case "fr":
                return "privacy-fr.html";

            default:
                return "privacy.html";
        }
    }

    render() {
        // Called with queryString parameter?
        if (!this.state.languageSet) {
            this.state.languageSet = true;

            const searchParams = new URLSearchParams(document.location.search);

            const lang = searchParams.get("lang");

            if (lang !== null && lang != undefined && lang !== "") {
                const languageContext = this.context;

                if (lang == "en" || lang === "es" || lang === "fr") {
                    languageContext.userLanguageChange(lang);
                } else {
                    languageContext.userLanguageChange("en");
                }
            }
        }

        const privacyContent = this.getPrivacyUrl();

        return (
            <div id="privacy">
                {/*
                <div className="section-title">
                    <h2>
                        <Text tid="Privacy" />
                    </h2>
                </div>
                */}
                <Card>
                    <Card.Body>
                        <StaticContent url={privacyContent} />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default Privacy;
