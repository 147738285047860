import React, { Component } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

import { Text } from "./language";

export class CookieConsentExt extends Component {
    render() {
        return (
            <CookieConsent
                disableStyles={true}
                containerClasses="consentcontainer"
                contentClasses="consentcontent"
                buttonWrapperClasses="consentbuttonwrapper"
                buttonClasses="consentbutton"
                location="none"
                sameSite="strict"
                buttonText={<Text tid="Consent.Button" />}
                onAccept={() => {
                    const myLanguageCache = Window && Window.myLanguageCache;
                    if (myLanguageCache) {
                        Cookies.set("language", myLanguageCache, {
                            path: "/",
                        });
                    }
                }}
            >
                <Text tid="Consent.Message" />{" "}
                <a
                    href={"https://loncartechnologies.com/privacy.php"}
                    style={{ color: "#fff", textDecoration: "underline" }}
                >
                    <Text tid="Consent.LearnMore" />
                </a>
                .
            </CookieConsent>
        );
    }
}

export default CookieConsentExt;
