import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

import Loading from "./loading";
import { Text } from "./language";

var phantom = {
    display: "block",
    marginTop: "30px",
    height: "40px",
    width: "100%",
    position: "relative",
    zIndex: 9000,
};

export class Footer extends Component {
    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        return (
            <div style={phantom}>
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col>
                            <Button
                                href="/privacy"
                                variant="link"
                                style={{
                                    display: "flex",
                                    alignSelf: "self-start",
                                }}
                            >
                                <Text tid="Privacy" />
                            </Button>
                        </Col>
                        <Col style={{ display: "contents" }}>
                            {this.props.data.copyright}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Footer;
