// a react component to inject the returned html
import DOMPurify from "dompurify";
import React, { Component } from "react";

export class StaticContent extends Component {
    constructor(props) {
        super(props);

        // Initial state
        this.state = { url: "", html: "" };
    }

    doRenderMyPage() {
        // If current url is not loaded
        if (this.props.url !== this.state.url) {
            // fetch the HTML fragment with a local API request
            fetch(`/${this.props.url}`) // Load from public folder
                .then((resp) => resp.text())
                .then((html) => {
                    // Set state with new content and html content
                    this.setState({ url: this.props.url, html: html });
                })
                .catch((err) => {
                    // handle the error
                    this.setState({
                        url: this.props.url,
                        html: `<p>Errors found: ${err}</p>`,
                    });
                });
        }
    }

    componentDidUpdate() {
        this.doRenderMyPage();
    }

    componentDidMount() {
        this.doRenderMyPage();
    }

    render() {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.state.html),
                }}
            />
        );
    }
}

export default StaticContent;
