import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";

export const dictionaryList = { en, es, fr };

export const languageOptions = {
    en: "English",
    es: "Español",
    fr: "Français",
};
